import React from 'react'
import Link from 'gatsby-link'
import Layout from '../../../components/layout'
import BodyClassName from 'react-body-classname';
import PageTransition from 'gatsby-v2-plugin-page-transitions'
import "animate.css/animate.css";
import ScrollAnimation from 'react-animate-on-scroll';
import acomLogo from './ancestry-logo.svg';
import styled from "@emotion/styled";
import Principles from './principles.svg';
import Core from './core.svg';
import Timeline from './timeline.svg';


const RED = styled.span`
  color: #c00;
`


const H4 = styled.h4`
 text-transform: uppercase;
 letter-spacing: .05em;
 color: #c00;
`
const Stack = styled.div`
  margin-top:40px;
  background-color: #efefef;
`

const CatTitle = styled.p`
font-size: .75em;
text-transform: uppercase;
color: #000;
opacity: .5;
letter-spacing: .1  em;
`

const H1 = styled.h1`
margin-top: 0;
`



export default () => (
  <Layout>
    <PageTransition>
      <BodyClassName className="">
        <div className="page">
          <article className="">
            <section className="contentSection">
              <div className="row">
                <div className="col-xs-9">
                  <header>
                  <Link to="./casestudies"><CatTitle>Case Study</CatTitle></Link>
                    <H1>Team <RED>RED</RED></H1>
                  </header>
                </div>
              </div>

              <div className="row">
                <div className="col-xs-12 col-sm-7 col-md-8">
                  <H4>The Problem</H4>
                  <p className="introText">
                    In a matrixed design organizations where an individual designers are embedded in agile teams it can be difficult to accomplish regular feature work and develop lots of new ideas and concepts simultaneously.
                  </p>
                  <H4>Solution</H4>
                  <p className="introText">
                    A small team was organized of talented designers with a focus on rapid design prototyping.
                 </p>
                  <p className="introText">
                    The teams charter was to identify high value concepts and bring them to life in conjunction with agile teams over a fixed period of time.
                  </p>
                </div>

                <div className="col-xs-12 col-sm-5 col-md-4">
                  <ScrollAnimation animateIn="fadeIn" duration={.5}>
                    <p><img className="full" src={acomLogo} style={{ "maxWidth": "300px" }} alt="Ancestry" /></p>

                    <ul>
                      <li>Organized <RED>RED</RED> team, defining objectives and engagement process.</li>
                      <li>Worked along side <RED>RED</RED> team during several engagements across the company.</li>
                      <li>Produced several concepts that were implemented in Ancestry's various products.</li>
                      <li><RED>RED</RED> engagements recived high praise from product management and engineering who were involved in the process.</li>
                    </ul>
                  </ScrollAnimation>
                </div>
              </div>
            </section>

            <Stack>
            <section className="contentSection">
              <h2 className="metalab-heading"><RED>RED</RED> Principles</h2>
              <div className="row">
                <img src={Principles} height="200px"/>
                <p>Defining our relationship with teams early on set expectations that gave us freedom to work our way, rather than falling into set ways of working already existing on the teams we hoped to help.</p>
                <p><strong>More building, less talking</strong> — This was designed to keep us out of long meetings and clearly set our bias toward prototyping and demonstrating how somethting could work rather than talking about it.</p>
                <p><strong>Work with, not for</strong> — We wanted to ensure that the teams we worked with understood that we were coming in as partners, not extra resources. We expected them to engage in our process as much as we wanted them to expect our involvement in their work.</p>
                <p><strong>Be adaptive, not rigid</strong> - As we worked with teams, we quickly realized that every situation was different. We couldn't do the same cookie cuttter approach and needed to be flexible to get the best results with each team.</p>
              </div>
              
            </section>
            </Stack>


            <div>
              <section className="contentSection">
                <h2><RED>RED</RED> Charter – What we provided</h2>
                <div className="row">
                  <div className="col-xs-12">   
                  <img src={Core} height="250px"/>                  
                      <p><strong>Rapid Experimentation</strong>—we bring concepts to life through a variety of artifacts, including fully functioning protyptes on all platforms (desktop, mobile & native mobile). We prefer using live or simulated data & specialize in prototypes that go beyond those created with Invision or similar tools.</p>
                      <p><strong>Customer Validation</strong>—we partner with our user research team to validate ideas early.</p>
                      <p><strong>Motion Design Expertise</strong>—we help improve interfaces and ideas through the use of motion and live interactions.</p>                    
                  </div>
                
                </div>
              </section>
            </div>

            <Stack>
              <section className="contentSection">
                <h2><RED>RED</RED> Process–How we worked</h2>
                <div className="row">
                  <div className="col-xs-12">   
                  <img src={Timeline} height="200px"/>                  
                      <p>Each engagement began with a kickoff meeting. The goal of this meeting was to make introductions and begin to explore concepts and ideas that the team already had, but may not have had resources or time to work on.</p>                    
                      <p>After identifying possible concepts, we worked through an evaluation exercise to ensure that we would only be spending our time on concepts that could be explored quickly, but would also have the potential to make a larger impact.</p>      
                      <p>Our engagement with each team would last for a few sprints - enough time to generate a number of ideas, and garner excitetment in the product teams as they began to see their ideas quickly take shape.</p>              
                  </div>
                
                </div>
              </section>
            </Stack>
    </article>

  </div>

  </BodyClassName>
  </PageTransition>
  </Layout >
)
